// import ISO6391 from 'iso-639-1'
import { filterFromRoute } from '@c/util'
import { getClient } from '../../../../store/src/axios'

const client = getClient('/workspaces')

export async function getCurationTasks(
  workspaceId,
  query = '',
  filters = {},
  activeFilters,
  impersonatedMember
) {
  const result = await client.post(
    `/${workspaceId}/curation/tasks/search/`,
    {
      ...filters,
      ...(query ? { query } : {})
    },
    {
      params: {
        page_size: 999,
        included_facets: ['topics', 'organisations', 'industries'].join(' '),
        ...(impersonatedMember ? { act_as: impersonatedMember } : {})
      }
    }
  )
  const { count, results } = result.data
  const { tasks, ...res } = results

  const resources = tasks.map((t) => ({
    ...t.resource,
    views: t.views,
    clicks: t.clicks
  }))

  const filterBlocks = getCurationFilterBlocks(res, activeFilters)

  return {
    filterBlocks,
    resources,
    count
  }
}

function getCurationFilterBlocks(res, activeFilters) {
  const filterBlocks = [
    {
      title: 'Topics',
      type: 'topics',
      options: res.topics.map((option) => ({
        active: filterFromRoute(activeFilters, 'topics').includes(option.name),
        aliases: option.aliases,
        count: undefined,
        // count: option.count,
        key: option.name
      }))
    },
    {
      title: 'Organisation',
      type: 'organisations',
      options: res.organisations.map((option) => ({
        active: filterFromRoute(activeFilters, 'organisations').includes(
          option.name
        ),
        aliases: option.aliases,
        count: undefined,
        // count: option.count,
        key: option.name
      }))
    },
    {
      title: 'Industry',
      type: 'industries',
      options: res.industries.map((option) => ({
        active: filterFromRoute(activeFilters, 'industries').includes(
          option.name
        ),
        aliases: option.aliases,
        count: undefined,
        // count: option.count,
        key: option.name
      }))
    }
    // {
    //   title: 'Content class',
    //   type: 'content_classes',
    //   options: Object.keys(res.content_classes).map((key) => ({
    //     active: filterFromRoute(activeFilters, 'content_classes').includes(key),
    //     count: undefined,
    //     // count: res.created_dates[key],
    //     key
    //   }))
    // },
    // {
    //   title: 'Creation date',
    //   type: 'created_dates',
    //   options: Object.keys(res.created_dates).map((key) => ({
    //     active: filterFromRoute(activeFilters, 'created_dates').includes(key),
    //     count: undefined,
    //     // count: res.created_dates[key],
    //     key
    //   }))
    // },
    // {
    //   title: 'Last modified',
    //   type: 'modified_dates',
    //   options: Object.keys(res.modified_dates).map((key) => ({
    //     active: filterFromRoute(activeFilters, 'modified_dates').includes(key),
    //     count: undefined,
    //     // count: res.modified_dates[key],
    //     key
    //   }))
    // },
    // {
    //   title: 'Language',
    //   type: 'languages',
    //   options: Object.keys(res.languages).map((key) => ({
    //     active: filterFromRoute(activeFilters, 'languages')
    //       .includes(ISO6391.getName(key.includes('zh-') ? 'zh' : key)),
    //     count: undefined,
    //     // count: res.modified_dates[key],
    //     key: ISO6391.getName(key.includes('zh-') ? 'zh' : key)
    //   }))
    // },
    // {
    //   title: 'Author',
    //   type: 'authors',
    //   options: res.authors.map((option) => ({
    //     active: filterFromRoute(activeFilters, 'authors').includes(option.name),
    //     count: undefined,
    //     // count: option.count,
    //     key: option.uuid,
    //     title: option.name
    //   }))
    // }
  ].filter((block) => block.options.length > 0)
  return filterBlocks
}
