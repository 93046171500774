var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-wrapper" }, [
    _c(
      "table",
      { staticClass: "table" },
      [
        _c(
          "tr",
          { staticClass: "table-headers" },
          [
            _vm._l(_vm.headers, function(header) {
              return _c(
                "th",
                { key: header.title, staticClass: "table-headers-title" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "table-headers-title-wrapper",
                      class: { sortable: header.sort },
                      on: {
                        click: function($event) {
                          return _vm.$emit("sort", header)
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(header.title) + " "),
                      header.sort
                        ? _c("img", {
                            staticClass: "table-headers-title-sort",
                            class: {
                              ascending:
                                _vm.sortBy.title === header.title &&
                                _vm.sortBy.order === "asc"
                            },
                            attrs: {
                              src: require("@/assets/icons/table_sort" +
                                (_vm.sortBy.title === header.title
                                  ? "_desc"
                                  : "") +
                                ".svg"),
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            !_vm.unlabeledOnly
              ? _c("th", { staticClass: "table-headers-title" }, [
                  _vm._v("Label")
                ])
              : _vm._e(),
            _vm._m(0)
          ],
          2
        ),
        _vm._l(_vm.resources, function(res) {
          return _c(
            "tr",
            {
              key: res.title,
              staticClass: "table-resource",
              on: {
                mouseenter: function($event) {
                  _vm.hovered = res.uuid
                },
                mouseleave: function($event) {
                  _vm.hovered = ""
                }
              }
            },
            [
              _vm._l(_vm.headers, function(header) {
                return _c(
                  "td",
                  {
                    key: res.title + "-" + header.title,
                    staticClass: "table-resource-value"
                  },
                  [
                    header.title === "Name"
                      ? _c("CurationTableResource", {
                          attrs: {
                            resource: res,
                            header: header,
                            hovered: _vm.hovered === res.uuid
                          }
                        })
                      : _c("p", [_vm._v(" " + _vm._s(header.key(res)) + " ")])
                  ],
                  1
                )
              }),
              !_vm.unlabeledOnly
                ? _c("td", { staticClass: "table-resource-value" }, [
                    Object.keys(res.label || {}).length > 0
                      ? _c(
                          "div",
                          { staticClass: "table-resource-label-wrapper" },
                          [
                            _c("img", {
                              staticClass: "table-resource-label",
                              attrs: {
                                src: require("@/assets/icons/" +
                                  res.label.type +
                                  "QualityLabel.svg"),
                                alt: ""
                              },
                              on: {
                                mouseenter: function($event) {
                                  _vm.hoveredLabel = res.uuid
                                },
                                mouseleave: function($event) {
                                  _vm.hoveredLabel = ""
                                }
                              }
                            }),
                            _c("QualityLabelInfo", {
                              attrs: {
                                label: res.label,
                                hovered: _vm.hoveredLabel === res.uuid,
                                "align-left": false
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _c(
                "td",
                { staticClass: "table-resource-value" },
                [
                  _c("EditLabels", {
                    attrs: {
                      "always-show": true,
                      "active-label": res.label || {},
                      "source-id": res.uuid,
                      "align-left": false,
                      "resource-level": "resource"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        }),
        _c("tr", {
          staticClass: "table-bottom-padding",
          class: { "large-padding": _vm.resources.length < 10 }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "table-headers-title" }, [_c("span")])
  }
]
render._withStripped = true

export { render, staticRenderFns }