var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resource" },
    [
      _c("img", {
        class: { "resource-image": true, hidden: !_vm.imageLoaded },
        attrs: { src: _vm.resource.subresources[0].preview, alt: "" },
        on: {
          load: function($event) {
            _vm.imageLoaded = true
          },
          click: _vm.handlePreviewClick
        }
      }),
      !_vm.imageLoaded ? _c("b-skeleton") : _vm._e(),
      _c("div", { staticClass: "resource-info" }, [
        _c(
          "div",
          { staticClass: "resource-info-title" },
          [
            _vm.resource.integrationfile.mimetype
              ? _c("DocumentTag", {
                  staticClass: "resource-info-title-tag",
                  attrs: { mimetype: _vm.resource.integrationfile.mimetype }
                })
              : _vm._e(),
            _c(
              "p",
              { staticClass: "blue header xs cursor-pointer no-select" },
              [_vm._v(" " + _vm._s(_vm.header.key(_vm.resource)) + " ")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "resource-info-sub" },
          [
            _c("IntegrationLink", {
              staticClass: "resource-info-sub-integration",
              attrs: {
                hover: _vm.hovered,
                integration: _vm.resource.integrationfile.integration,
                "integration-path": _vm.resource.integrationfile.path
              }
            }),
            _c(
              "b-tooltip",
              {
                staticClass: "resource-info-sub-permission",
                attrs: {
                  label: _vm.resource.integrationfile.permissions.is_public
                    ? "Public"
                    : "Synced permissions",
                  type: "is-white",
                  position: "is-right"
                }
              },
              [
                _c("b-icon", {
                  staticClass: "body body-text",
                  attrs: {
                    size: "is-small",
                    icon: _vm.resource.integrationfile.permissions.is_public
                      ? "earth"
                      : "account-multiple"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }