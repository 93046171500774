var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "switch" }, [
    _c("input", {
      attrs: { id: "switch", type: "checkbox" },
      domProps: { value: _vm.toggled, checked: _vm.toggled },
      on: {
        click: function($event) {
          return _vm.handleClick()
        }
      }
    }),
    _c("label", { attrs: { for: "switch" } }, [_vm._v("Toggle")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }