var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "curate" },
    [
      _vm.loadingCuration
        ? _c(
            "div",
            { staticClass: "curate-loading-wrapper" },
            [
              _c("b-loading", {
                attrs: { active: _vm.loadingCuration, "is-full-page": false }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "curate-search" },
            [
              _c("SearchBar", {
                staticClass: "pv-4 curate-search-searchbar",
                attrs: { placeholder: "Search...", rounded: false },
                on: {
                  historyItemClick: function($event) {
                    return _vm.search("history")
                  },
                  autocompleteItemClick: function($event) {
                    return _vm.search("autocomplete")
                  },
                  clear: function($event) {
                    return _vm.handleClear()
                  }
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    ) {
                      return null
                    }
                    return _vm.search("input")
                  }
                },
                model: {
                  value: _vm.query,
                  callback: function($$v) {
                    _vm.query = $$v
                  },
                  expression: "query"
                }
              }),
              _c(
                "div",
                { staticClass: "curate-search-toggle" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "curate-search-toggle-label",
                      on: {
                        click: function($event) {
                          return _vm.handleUnlabeledToggle(!_vm.unlabeledOnly)
                        }
                      }
                    },
                    [_vm._v(" Hide curated items ")]
                  ),
                  _c("SwitchToggle", {
                    attrs: { toggled: _vm.unlabeledOnly },
                    on: { "update:toggled": _vm.handleUnlabeledToggle }
                  })
                ],
                1
              ),
              _c("HorizontalFilters", {
                staticClass: "pb-4",
                attrs: {
                  "top-filters": _vm.filterBlocks,
                  "filters-loading": _vm.loadingCuration
                },
                on: {
                  optionClicked: _vm.filterSelected,
                  clearFiltersByType: _vm.clearFiltersByType,
                  saveFilters: _vm.saveFilters
                }
              })
            ],
            1
          ),
      _c("div", { ref: "curatecontent" }, [
        !(_vm.loadingCuration || _vm.searchEmpty)
          ? _c(
              "div",
              {
                staticClass: "curate-search-content",
                style: _vm.maxHeightStyle
              },
              [
                _vm.loadingSort
                  ? _c(
                      "div",
                      { staticClass: "curate-search-content-loading-overlay" },
                      [
                        _c("b-loading", {
                          attrs: {
                            active: _vm.loadingSort,
                            "is-full-page": false
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("CurationTable", {
                  attrs: {
                    resources: _vm.resources,
                    headers: _vm.tableHeaders,
                    "sort-by": _vm.sortBy,
                    "unlabeled-only": _vm.unlabeledOnly
                  },
                  on: {
                    sort: function(header) {
                      return _vm.sort(header, false, true)
                    }
                  }
                })
              ],
              1
            )
          : !_vm.loadingCuration && _vm.searchEmpty
          ? _c("div", { staticClass: "curate-search-empty" }, [
              _c("p", [
                _vm._v(
                  " Sorry, we couldn't find any documents users interacted with that match your search query "
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("ResourceModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }