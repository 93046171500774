<template>
  <div class="resource">
    <img
      :src="resource.subresources[0].preview"
      alt=""
      :class="{ 'resource-image': true, hidden: !imageLoaded }"
      @load="imageLoaded = true"
      @click="handlePreviewClick"
    />
    <b-skeleton v-if="!imageLoaded"></b-skeleton>
    <div class="resource-info">
      <div class="resource-info-title">
        <DocumentTag
          v-if="resource.integrationfile.mimetype"
          class="resource-info-title-tag"
          :mimetype="resource.integrationfile.mimetype"
        />
        <p class="blue header xs cursor-pointer no-select">
          {{ header.key(resource) }}
        </p>
      </div>
      <div class="resource-info-sub">
        <IntegrationLink
          class="resource-info-sub-integration"
          :hover="hovered"
          :integration="resource.integrationfile.integration"
          :integration-path="resource.integrationfile.path"
        />
        <b-tooltip
          class="resource-info-sub-permission"
          :label="
            resource.integrationfile.permissions.is_public
              ? 'Public'
              : 'Synced permissions'
          "
          type="is-white"
          position="is-right"
        >
          <b-icon
            class="body body-text"
            size="is-small"
            :icon="
              resource.integrationfile.permissions.is_public
                ? 'earth'
                : 'account-multiple'
            "
          />
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationLink from '@c/shared/molecules/object-visualisations/resource/subcomponents/IntegrationLink.vue'
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'
import { mapActions } from 'vuex'

export default {
  name: 'CurationTableResource',
  components: { IntegrationLink, DocumentTag },
  props: {
    resource: {
      type: Object,
      required: true
    },
    header: {
      type: Object,
      required: true
    },
    hovered: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    imageLoaded: false
  }),
  methods: {
    ...mapActions(['setResourceModal']),
    handlePreviewClick() {
      this.setResourceModal({
        resource_id: this.resource.uuid,
        resource_level: 'resource',
        resource_modal_props: {
          workspaceId: this.$route.params.workspace_id,
          initialPage: 1,
          selectable: false,
          scrollTo: '',
          similarPages: [],
          rank: 0,
          resourceType: this.resource.type,
          resourceTraceId: this.resource.trace_id,
          curationModal: true
        },
        buefy_modal_props: {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.resource {
  display: flex;
  gap: 0.5rem;
  &-image {
    height: 3.5rem;
    border-radius: 4px;
    border: 1px solid rgba(#000, 8%);
    aspect-ratio: 16 / 9;
    cursor: zoom-in;

    &.hidden {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    &-title {
      display: flex;
      gap: 0.5rem;
    }

    &-sub {
      display: flex;
      gap: 0.5rem;

      &-integration {
        display: flex;
        flex-flow: row wrap;
      }

      &-permission {
      }
    }
  }
}

::v-deep .b-skeleton-item {
  height: 3.5rem;
  aspect-ratio: 16 / 9;
  cursor: zoom-in;
}

::v-deep .b-skeleton {
  width: unset !important;
}
</style>
