<template>
  <div class="table-wrapper">
    <table class="table">
      <tr class="table-headers">
        <th
          v-for="header in headers"
          :key="header.title"
          class="table-headers-title"
        >
          <p
            class="table-headers-title-wrapper"
            :class="{ sortable: header.sort }"
            @click="$emit('sort', header)"
          >
            {{ header.title }}
            <img
              v-if="header.sort"
              :src="
                require(`@/assets/icons/table_sort${
                  sortBy.title === header.title ? '_desc' : ''
                }.svg`)
              "
              alt=""
              class="table-headers-title-sort"
              :class="{
                ascending:
                  sortBy.title === header.title && sortBy.order === 'asc'
              }"
            />
          </p>
        </th>
        <th v-if="!unlabeledOnly" class="table-headers-title">Label</th>
        <th class="table-headers-title"><span></span></th>
      </tr>
      <tr
        v-for="res in resources"
        :key="res.title"
        class="table-resource"
        @mouseenter="hovered = res.uuid"
        @mouseleave="hovered = ''"
      >
        <td
          v-for="header in headers"
          :key="`${res.title}-${header.title}`"
          class="table-resource-value"
        >
          <CurationTableResource
            v-if="header.title === 'Name'"
            :resource="res"
            :header="header"
            :hovered="hovered === res.uuid"
          />
          <p v-else>
            {{ header.key(res) }}
          </p>
        </td>
        <td v-if="!unlabeledOnly" class="table-resource-value">
          <div
            v-if="Object.keys(res.label || {}).length > 0"
            class="table-resource-label-wrapper"
          >
            <img
              :src="require(`@/assets/icons/${res.label.type}QualityLabel.svg`)"
              alt=""
              class="table-resource-label"
              @mouseenter="hoveredLabel = res.uuid"
              @mouseleave="hoveredLabel = ''"
            />
            <QualityLabelInfo
              :label="res.label"
              :hovered="hoveredLabel === res.uuid"
              :align-left="false"
            />
          </div>
        </td>
        <td class="table-resource-value">
          <EditLabels
            :always-show="true"
            :active-label="res.label || {}"
            :source-id="res.uuid"
            :align-left="false"
            resource-level="resource"
          />
        </td>
      </tr>
      <tr
        class="table-bottom-padding"
        :class="{ 'large-padding': resources.length < 10 }"
      ></tr>
    </table>
  </div>
</template>

<script>
import EditLabels from '@c/shared/molecules/object-visualisations/resource/subcomponents/EditLabels.vue'
import QualityLabelInfo from '@c/shared/molecules/object-visualisations/resource/subcomponents/QualityLabelInfo.vue'
import CurationTableResource from './CurationTableResource.vue'

export default {
  name: 'CurationTable',
  components: { CurationTableResource, EditLabels, QualityLabelInfo },
  props: {
    resources: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    sortBy: {
      type: Object,
      required: true
    },
    unlabeledOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hovered: '',
    hoveredLabel: ''
  })
}
</script>

<style scoped lang="scss">
.table {
  width: 100%;

  &-wrapper {
    width: 100%;
  }

  &-headers {
    &-title {
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;

      &:first-child {
        padding-left: 0.5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }

      &-wrapper {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        white-space: nowrap;
        width: fit-content;

        &.sortable {
          cursor: pointer;
        }
      }

      &-sort {
        transition: transform ease 200ms;
        &.ascending {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-resource {
    &-value {
      vertical-align: middle;

      &:first-child {
        padding-left: 0.5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }
    }

    &-label {
      &-wrapper {
        position: relative;
      }
    }
  }

  &-bottom-padding {
    height: 100px;

    &.large-padding {
      height: 300px;
    }
  }
}

.centered {
  vertical-align: middle;
}
</style>
