<template>
  <div class="curate">
    <div v-if="loadingCuration" class="curate-loading-wrapper">
      <b-loading :active="loadingCuration" :is-full-page="false"></b-loading>
    </div>
    <div v-else class="curate-search">
      <SearchBar
        v-model="query"
        class="pv-4 curate-search-searchbar"
        placeholder="Search..."
        :rounded="false"
        @keyup.native.enter.exact="search('input')"
        @historyItemClick="search('history')"
        @autocompleteItemClick="search('autocomplete')"
        @clear="handleClear()"
      />
      <div class="curate-search-toggle">
        <p
          class="curate-search-toggle-label"
          @click="handleUnlabeledToggle(!unlabeledOnly)"
        >
          Hide curated items
        </p>
        <SwitchToggle
          :toggled="unlabeledOnly"
          @update:toggled="handleUnlabeledToggle"
        />
      </div>
      <HorizontalFilters
        class="pb-4"
        :top-filters="filterBlocks"
        :filters-loading="loadingCuration"
        @optionClicked="filterSelected"
        @clearFiltersByType="clearFiltersByType"
        @saveFilters="saveFilters"
      />
    </div>
    <div ref="curatecontent">
      <div
        v-if="!(loadingCuration || searchEmpty)"
        class="curate-search-content"
        :style="maxHeightStyle"
      >
        <!-- <p class="curate-search-content-header">{{ resultsCount }}</p> -->
        <div v-if="loadingSort" class="curate-search-content-loading-overlay">
          <b-loading :active="loadingSort" :is-full-page="false"></b-loading>
        </div>
        <CurationTable
          :resources="resources"
          :headers="tableHeaders"
          :sort-by="sortBy"
          :unlabeled-only="unlabeledOnly"
          @sort="(header) => sort(header, false, true)"
        />
      </div>
      <div
        v-else-if="!loadingCuration && searchEmpty"
        class="curate-search-empty"
      >
        <p>
          Sorry, we couldn't find any documents users interacted with that match
          your search query
        </p>
      </div>
    </div>
    <ResourceModal />
  </div>
</template>

<script>
import { routeSafe } from '@/util'
import HorizontalFilters from '@c/shared/molecules/filters/HorizontalFilters.vue'
import SearchBar from '@c/shared/molecules/inputs/SearchBar.vue'
import SwitchToggle from '@c/library/SwitchToggle.vue'
import ResourceModal from '@c/shared/molecules/object-visualisations/resource/subcomponents/ResourceModal.vue'
import { filterFromRoute } from '@c/util'
import { isEmpty } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import CurationTable from './curation/CurationTable.vue'
import { getCurationTasks } from './curation/curationSearchService'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

export default {
  name: 'CurationPage',
  components: {
    HorizontalFilters,
    SearchBar,
    CurationTable,
    SwitchToggle,
    ResourceModal
  },
  mixins: [MaxHeightMixin],
  data: () => ({
    loadingCuration: true,
    loadingSort: false,
    filterBlocks: [],
    allResources: [],
    unlabeledResources: [],
    resources: [],
    count: 0,
    total: 0,
    query: '',
    sortBy: {
      title: 'Clicks',
      order: 'desc'
    },
    unlabeledOnly: false
  }),
  computed: {
    ...mapState({
      workspaces: (state) => state.workspaces.workspaces
    }),
    ...mapGetters(['canWriteLabels', 'currentWorkspace']),
    resultsCount() {
      return this.count === this.total
        ? `${this.total} tasks remaining`
        : `Showing ${this.count} of ${this.total} tasks`
    },
    tableHeaders() {
      return [
        {
          title: 'Name',
          key: (res) => res.title,
          sort: true
        },
        {
          title: 'Author',
          key: (res) =>
            this.getFullName(res.integrationfile.external_created_by),
          sort: true
        },
        {
          title: 'Last modified',
          key: (res) =>
            this.formatDate(res.integrationfile.external_modified_date),
          sort: true
        },
        {
          title: 'Creation date',
          key: (res) =>
            this.formatDate(res.integrationfile.external_created_date),
          sort: true
        },
        {
          title: 'Views',
          key: (res) => res.views,
          sort: true
        },
        {
          title: 'Clicks',
          key: (res) => res.clicks,
          sort: true
        }
      ]
    },
    queryFromURL() {
      return this.$route.query
    },
    searchEmpty() {
      return (this.resources || []).length === 0
    },
    impersonatedMember() {
      return this.$route.query.act_as
    }
  },
  watch: {
    queryFromURL: {
      handler(newVal) {
        if (isEmpty(newVal)) this.resetCurationContent()
        this.getCurationContent()
      },
      deep: true
    },
    impersonatedMember() {
      this.getCurationContent()
    },
    loadingCuration(val) {
      if (!val) this.$nextTick(this.checkMaxHeight)
    }
  },
  mounted() {
    this.query = this.$route.query.query || ''
    this.getCurationContent()
    if (
      !this.currentWorkspace ||
      this.currentWorkspace.uuid !== this.$route.params.workspace_id
    ) {
      this.setCurrentWorkspace(this.$route.params.workspace_id)
    }
  },
  methods: {
    ...mapActions(['setCurrentWorkspace']),
    getMaxHeightElement() {
      return this.$refs.curatecontent
    },
    async getCurationContent() {
      this.loadingCuration = true
      try {
        const { filterBlocks, resources, count } = await getCurationTasks(
          this.$route.params.workspace_id,
          this.query,
          isEmpty(this.filterBlocks)
            ? this.filtersFromQuery()
            : this.filterBlocks.reduce(
                (prev, curr) => ({
                  ...prev,
                  ...(curr.options.filter((opt) => opt.active).length > 0
                    ? {
                        [curr.type]: curr.options
                          .filter((opt) => opt.active)
                          .map((opt) => opt.key)
                      }
                    : {})
                }),
                {}
              ),
          this.$route,
          this.$route.query.act_as
        )
        this.filterBlocks = filterBlocks
        this.allResources = resources
        this.unlabeledResources = resources.filter(
          (res) => (res.labels || []).length === 0
        )
        this.count = count
        this.sortBy = {
          title: 'Clicks',
          order: 'desc'
        }
        this.sort(
          this.tableHeaders.find((h) => h.title === 'Clicks'),
          true
        )
      } catch (e) {
        this.$console.debug('Error during curation items retrieval', e)
        this.$toast.error(e, 'loading curation content')
      } finally {
        this.loadingCuration = false
      }
    },
    resetCurationContent() {
      this.filterBlocks = []
      this.resources = []
      this.count = 0
      this.total = 0
      this.query = ''
    },
    search() {
      this.$router.push({
        ...this.$route,
        query: {
          ...(this.query === '' ? {} : { query: this.query })
        }
      })
    },
    handleClear() {
      this.query = ''
      this.search()
    },
    getFullName(user) {
      return user ? this.$umodel.full_name(user) : '-'
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD MMM YYYY') : '-'
    },
    sort(header, initial = false) {
      const alphabetical = ['Name', 'Author', 'Last modified', 'Creation date']
      if (!initial) {
        this.loadingSort = true
        this.sortBy = {
          order:
            this.sortBy.title !== header.title
              ? alphabetical.includes(header.title)
                ? 'asc'
                : 'desc'
              : this.sortBy.order === 'asc'
              ? 'desc'
              : 'asc',
          title: header.title
        }
      }
      const k = ['Last modified', 'Creation date'].includes(header.title)
        ? (res) =>
            new Date(
              res.integrationfile[
                header.title === 'Last modified'
                  ? 'external_modified_date'
                  : 'external_created_date'
              ]
            ).getTime()
        : header.key
      const f = (a, b) =>
        this.sortBy.order === 'desc'
          ? k(a) > k(b)
            ? -1
            : k(a) < k(b)
            ? 1
            : 0
          : k(a) < k(b)
          ? -1
          : k(a) > k(b)
          ? 1
          : 0
      this.resources = this.unlabeledOnly
        ? this.unlabeledResources
        : this.allResources
      this.resources.sort(f)
      if (!initial) this.loadingSort = false
    },
    filterSelected(filter) {
      const { option, type } = filter
      const typeIdx = this.filterBlocks.findIndex((el) => el.type === type)
      if (typeIdx === -1) return
      const optionIdx = this.filterBlocks[typeIdx].options.findIndex(
        (el) => el.key === option.key
      )
      if (optionIdx === -1) return
      const active = this.filterBlocks[typeIdx].options[optionIdx].active
      this.filterBlocks[typeIdx].options[optionIdx].active = !active
    },
    handleUnlabeledToggle(toggled) {
      this.unlabeledOnly = toggled
      this.resources = this.unlabeledOnly
        ? this.unlabeledResources
        : this.allResources
    },
    clearFiltersByType(type) {
      let newQuery = { ...this.$route.query }
      delete newQuery[type]
      this.$router.push({
        ...this.$route,
        query: newQuery
      })
    },
    saveFilters() {
      let newQuery = {}
      for (const block of this.filterBlocks) {
        for (const option of block.options) {
          if (option.active) {
            newQuery[block.type] = newQuery[block.type]
              ? [
                  ...newQuery[block.type].split(','),
                  routeSafe(option.key)
                ].join(',')
              : routeSafe(option.key)
          }
        }
      }
      this.$router.push({
        ...this.$route,
        query: newQuery
      })
    },
    filtersFromQuery() {
      return Object.keys(this.$route.query)
        .filter((key) => key !== 'query' && key !== 'act_as')
        .reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: filterFromRoute(this.$route, curr)
          }),
          {}
        )
    }
  }
}
</script>

<style scoped lang="scss">
.curate {
  &-loading-wrapper {
    position: relative;
    min-height: 10vh;
  }

  &-search {
    padding: 2.5rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 1rem;

    &-searchbar {
      width: min(100%, 30rem);
      padding: 0 !important;

      &::v-deep input {
        height: 2.5rem !important;
        padding: 0.5rem 1rem;
      }
    }

    &-toggle {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &-label {
        cursor: pointer;
        font-weight: 600;
        color: #60666b;
      }
    }

    &-empty {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      align-items: center;
      font-size: 18px;

      p {
        color: #8f9399;
      }
    }

    &-content {
      position: relative;
      overflow-y: auto;
      padding: 0 2.5rem;

      &-loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 90vh;
      }
    }
  }
}
</style>
